
import AOS from 'aos'
import 'aos/dist/aos.css'

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  mounted(){
    AOS.init()
  }
});
